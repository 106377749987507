import React, { useState, useEffect } from "react";

import "../styles/consent-popup.css";
import {getParameterByName} from "../utils/formatter";

export default function ChatbasePopup(props) {
    const [viewPopup, setViewPopup] = useState(true);

    const consent = () => {
        setViewPopup(false);
    };

    const close = (
        <svg width="24" height="24" viewBox="0 0 24 24">
            <defs>
                <path
                    id="a"
                    d="M13.414 12l6.364-6.364a.999.999 0 1 0-1.414-1.414L12 10.586 5.636 4.222a.999.999 0 1 0-1.414 1.414L10.586 12l-6.364 6.364a.999.999 0 1 0 1.414 1.414L12 13.414l6.364 6.364a.995.995 0 0 0 .707.293.999.999 0 0 0 .707-1.707L13.414 12z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlinkHref="#a" />
                </mask>
                <g fill="#444" mask="url(#b)">
                    <path d="M0 0h24v24H0z" />
                </g>
            </g>
        </svg>
    );

    return (
        <React.Fragment>
            {viewPopup && (
                <div className="chatbase-popup">
                    <p>
                        ¿Tienes dudas?. Pregunta a nuestro {" "}
                        <a
                            href={"https://www.wollyhome.com/pablo-wolly"}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            agente
                        </a>
                        .
                    </p>
                    <div className="close-popup">
                        <button className="nostyle" onClick={consent}>
                            {close}
                        </button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}
